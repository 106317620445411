import React from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";

const DocumentModal = (props) => {
  const {
    status,
    handleModalClose,
    data,
    handleDocumentImageChange,
    expiryDate,
    setExpiryDate,
    handleDocumentUpload,
    picture_file,
  } = props;

  return (
    <>
      <div
        id="authModal"
        className={`overflow-y-scroll ${status ? "show" : ""}`}
      >
        <div className="authModalWrapper no-padding">
          {!status && (
            <div className="wrapper" onClick={() => handleModalClose()}></div>
          )}
          <div
            className={`modal-body addProject-modal col-lg-4 col-md-6 col-xs-11 col-sm-11 ${
              status ? "show" : ""
            }`}
          >
            <div
              className="outside-scroll"
              //onClick={() => handleModalClose()}
            ></div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
              <div className="headerwrapper">
                <h4 className="text-center text-capitalize">Document Upload</h4>
                <div className="modal-close" onClick={() => handleModalClose()}>
                  <svg className="woox-icon">
                    <use xlinkHref="#icon-error-circle"></use>
                  </svg>
                </div>
              </div>
              <div className="kyc-document-selector mt-4">
                {data != null && (
                  <>
                    <div className="col-12">
                      <div className="input-image-file-btn">
                        {Object.keys(data.user_document).length > 0 &&
                        picture_file == "" ? (
                          <img src={data.user_document.document_file} alt="" />
                        ) : (
                          <img src={data.picture} alt="" />
                        )}
                        <div className="kyc-img-modal-overlay">
                          <i class="far fa-image"></i>
                        </div>
                        <input
                          id="files"
                          type="file"
                          accept="image/*"
                          onChange={(event) => handleDocumentImageChange(event)}
                        ></input>
                      </div>
                    </div>
                    {data.is_expiry_date == 1 && (
                      <>
                        <div
                          id="customReactDateTimeCalender"
                          className="w-100 mt-3"
                        >
                          <DatePicker
                            onChange={setExpiryDate}
                            value={expiryDate}
                            format={"y-MM-dd"}
                            clearIcon={null}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col-lg-12 no-padding ">
                <div className="text-center mt-4">
                  <button
                    className="btn btn--medium btn--primary text-capitalize "
                    type="button"
                    disabled={props.addKycDocInput.buttonDisable}
                    onClick={handleDocumentUpload}
                  >
                    {props.addKycDocInput.loadingButtonContent != null
                      ? props.addKycDocInput.loadingButtonContent
                      : `Upload ${data.name}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default React.memo(
  connect(mapStateToPros, mapDispatchToProps)(DocumentModal)
);
