import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADD_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_LIST_START,
  GET_KYC_DOCUMENT_START,
  DELETE_KYC_DOCUMENT_START
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";
import {
  addKycDocumentSuccess,
  addKycDocumentFailure,
  getKycDocumentSuccess,
  getKycDocumentFailure,
  getKYCDocumentsListSuccess,
  getKYCDocumentsListFailure,
  getKYCDocumentsListStart,
  deleteKYCDocumentSuccess,
  deleteKycDocumentFailure
} from "../actions/KycDocumentAction";

function* addKycDocumentAPI(action) {
  const response = yield api.postMethod("documents_save", action.data);
  yield put(addKycDocumentSuccess(response.data.data));
  if (response.data.success) {
    const notificationMessage = getSuccessNotificationMessage(
      response.data.message
    );
    yield put(createNotification(notificationMessage));
    yield put(getKYCDocumentsListStart())
  } else {
    yield put(addKycDocumentFailure(response.data.error));
    const notificationMessage = getErrorNotificationMessage(
      response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* getKycDocumentAPI() {
  try {
    const response = yield api.postMethod("documents_list");
    yield put(getKycDocumentSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing..
    } else {
      yield put(getKycDocumentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getKycDocumentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getKycDocumentListAPI() {
  try {
    const response = yield api.postMethod("documents_list");
    if (response.data.success) {
      yield put(getKYCDocumentsListSuccess(response.data.data));
    } else {
      yield put(getKYCDocumentsListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getKYCDocumentsListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteKYCDocumentAPI(action) {
  try {
    const response = yield api.postMethod("documents_delete" , action.data);
    if (response.data.success) {
      console.log(response.data.data)
      yield put(deleteKYCDocumentSuccess(response.data.data));
      yield put(getKYCDocumentsListStart())
    } else {
      yield put(deleteKycDocumentFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteKycDocumentFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ADD_KYC_DOCUMENT_START, addKycDocumentAPI)]);
  yield all([yield takeLatest(GET_KYC_DOCUMENT_START, getKycDocumentAPI)]);
  yield all([yield takeLatest(GET_KYC_DOCUMENT_LIST_START, getKycDocumentListAPI)]);
  yield all([yield takeLatest(DELETE_KYC_DOCUMENT_START, deleteKYCDocumentAPI)]);
}
