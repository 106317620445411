import {
  GET_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_SUCCESS,
  GET_KYC_DOCUMENT_FAILURE,
  ADD_KYC_DOCUMENT_START,
  ADD_KYC_DOCUMENT_SUCCESS,
  ADD_KYC_DOCUMENT_FAILURE,
  GET_KYC_DOCUMENT_LIST_START,
  GET_KYC_DOCUMENT_LIST_SUCCESS,
  GET_KYC_DOCUMENT_LIST_FAILURE,
  CLEAR_DOCUMENT_UPLOAD_DATA,
  DELETE_KYC_DOCUMENT_START,
  DELETE_KYC_DOCUMENT_SUCCESS,
  DELETE_KYC_DOCUMENT_FAILURE,
  CLEAR_DELETE_KYC_DOCUMENT_DATA
} from "./ActionConstant";

// Get kyc document actions.

export function getKycDocumentStart(data) {
  return {
    type: GET_KYC_DOCUMENT_START,
    data,
  };
}

export function getKycDocumentSuccess(data) {
  return {
    type: GET_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function getKycDocumentFailure(error) {
  return {
    type: GET_KYC_DOCUMENT_FAILURE,
    error,
  };
}

// add kyc document actions.

export function addKycDocumentStart(data) {
  return {
    type: ADD_KYC_DOCUMENT_START,
    data,
  };
}

export function addKycDocumentSuccess(data) {
  return {
    type: ADD_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function addKycDocumentFailure(error) {
  return {
    type: ADD_KYC_DOCUMENT_FAILURE,
    error,
  };
}

export function getKYCDocumentsListStart(data) {
  return {
    type: GET_KYC_DOCUMENT_LIST_START,
    data,
  };
}

export function getKYCDocumentsListSuccess(data) {
  return {
    type: GET_KYC_DOCUMENT_LIST_SUCCESS,
    data,
  };
}

export function getKYCDocumentsListFailure(error) {
  return {
    type: GET_KYC_DOCUMENT_LIST_FAILURE,
    error,
  };
}

export const clearDocumentUploadData = () => {
  return {
    type : CLEAR_DOCUMENT_UPLOAD_DATA,
  }
}


export function deleteKYCDocumentStart(data) {
  return {
    type: DELETE_KYC_DOCUMENT_START,
    data,
  };
}

export function deleteKYCDocumentSuccess(data) {
  return {
    type: DELETE_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function deleteKycDocumentFailure(error) {
  return {
    type: DELETE_KYC_DOCUMENT_FAILURE,
    error,
  };
}

export function clearDeleteKycDocumentData(){
  return {
    type : CLEAR_DELETE_KYC_DOCUMENT_DATA
  }
}