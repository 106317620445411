import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveTokenStart } from "../store/actions/TokenAction";
import { Redirect, useHistory } from "react-router";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import ConnectWalletModal from "../account/auth/ConnectWalletModal";
import { useWeb3React } from "@web3-react/core";
import { authContext } from "../account/auth/AuthProvider";
import configuration from "react-global-configuration";
import axios from "axios";
import BinanceToken from "../../abis/BinanceToken.json";

const CreateTokenPayment = (props) => {
  const context = useWeb3React();
  const { chainId, account, active } = context;

  const history = useHistory();

  const { auth, setAuth, hanldeLogout } = useContext(authContext);

  const [loading, setLoading] = useState(true);

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [bnbAmount, setBnbAmount] = useState("0");

  const [payMeButtonContent, setPayMeButtonContent] = useState("");

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [deployContractButtonContent, setDeployContractButtonContent] =
    useState("");

  const [tokenContractData, setTokenContractData] = useState({
    contract_address: null,
    transaction_hash: null,
  });

  const handleConnectWalletClose = () => setConnectWalletModalStatus(false);
  const handleConnectWalletOpen = () => setConnectWalletModalStatus(true);

  useEffect(() => {
    if (payMeButtonContent !== "") {
      window.onbeforeunload = function () {
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [payMeButtonContent]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let web3 = window.web3;

      let response;
      setPayMeButtonContent("Initied. Please Wait...");

      console.log(auth.authStatus, auth);

      let account = auth.accounts;

      console.log(account);

      if (account != "") {
        // Send ether to admin account.

        let paymentAmount = Number(
          configuration.get("configData.token_deploy_amount")
            ? configuration.get("configData.token_deploy_amount")
            : 1
        );
        paymentAmount = paymentAmount.toString();
        response = await web3.eth.sendTransaction({
          from: account,
          to: "0x12B260c8aE793c8D2729cEc3b04247A52b53963F",
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
        });
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Please choose the network type..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
        return;
      }
      if (response !== null) {
        // Save the transaction details.
        console.log("Payment received..");
        setPayMeButtonContent("");
        setDeployContractButtonContent("Deploying contract...");
        const erc20Token = new web3.eth.Contract(BinanceToken.abi);
        console.log(erc20Token);
        const max_token_supply =
          props.tokenData.data.max_token_supply.toString();
        try {
          console.log("trybing 2");

          let transaction_hash;

          const res = await erc20Token
            .deploy({
              data: BinanceToken.bytecode,
              arguments: [
                props.tokenData.data.name,
                props.tokenData.data.token_symbol,
                max_token_supply,
                props.tokenData.data.decimal_points,
                account,
              ],
            })
            .send(
              {
                from: account,
              },
              function (error, transactionHash) {
                // API call....
                console.log("Txt", transactionHash);
                transaction_hash = transactionHash;
              }
            )
            .on("confirmation", (confirmationNumber, receipt) => {
              console.log("con", confirmationNumber);
            })
            .then(async (newContractInstance) => {
              console.log("New token created.", newContractInstance);
              console.log("transaction_hash", transaction_hash);
              setTokenContractData({
                ...tokenContractData,
                transaction_hash: transaction_hash,
                contract_address: newContractInstance.options.address,
              });
              console.log(
                "name",
                await newContractInstance.methods.name.call().toString()
              );
              props.dispatch(
                saveTokenStart({
                  ...props.tokenData.data,
                  description: "Some description static",
                  initial_token_supply: "10000",
                  wallet_address: account,
                  contract_address: newContractInstance.options.address,
                  transaction_hash: transaction_hash
                    ? transaction_hash
                    : Math.random(),
                })
              );
              const notificationMessage = getSuccessNotificationMessage(
                "Contract deployed. Please check the metamask.."
              );
              props.dispatch(createNotification(notificationMessage));
              console.log(newContractInstance.options.address); // instance with the new contract address
              setDeployContractButtonContent("");
              setPaymentCompleted(true);
              // Save the token contract address.
            });
        } catch (error) {
          console.log(error);
          const notificationMessage = getErrorNotificationMessage(
            "Failed. Please try again..."
          );
          props.dispatch(createNotification(notificationMessage));
          setDeployContractButtonContent("");
        }
      } else {
        const notificationMessage = getErrorNotificationMessage(
          "Payment Failed. Please try again..."
        );
        props.dispatch(createNotification(notificationMessage));
        console.log("Payment is not done..");
        setPayMeButtonContent("");
      }
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Payment Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      console.log("Payment is not done..", error);
      setPayMeButtonContent("");
    }
  };

  useEffect(() => {
    if (account) {
      handleConnectWalletClose();
    }
  }, [account]);

  const getPrice = async () => {
    const priceData = await axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data.binancecoin.usd;
        } else {
          return null;
        }
      });
    setBnbAmount(priceData);
  };

  useEffect(() => {
    getPrice();
  }, []);

  if (props.tokenData.data == null) {
    history.push("/token-creation");
    let notificationMessage = getErrorNotificationMessage(
      "Token details missing"
    );
    props.dispatch(createNotification(notificationMessage));
    return null;
  }

  return (
    <>
      <div className="" id="token-confirmation">
        <section>
          <div className="container">
            <div className="">
              <div className="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className=" align-items-center d-flex justify-content-center">
                    <div className="admin-amount-wrapper col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="invalid-feedback mb-4">
                        NOTE: Please connect your Metamask with the appropriate
                        network and make the payment to complete your token
                        generation
                      </div>
                      <div className="token-details-wrapper">
                        <div>
                          <h4>
                            {" "}
                            <span className="whitecolor text-bold">
                              Pay Amount in BNB
                            </span>{" "}
                            :{" "}
                            {configuration.get(
                              "configData.token_deploy_amount"
                            )}{" "}
                            BNB
                          </h4>
                          <h4>
                            {" "}
                            <span className="whitecolor text-bold">
                              Pay Amount in USD
                            </span>{" "}
                            :{" "}
                            {(
                              Number(bnbAmount) *
                              Number(
                                configuration.get(
                                  "configData.token_deploy_amount"
                                )
                                  ? configuration.get(
                                      "configData.token_deploy_amount"
                                    )
                                  : 1
                              )
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 3,
                            })}{" "}
                            USD
                          </h4>

                          {auth.authStatus && (
                            <>
                              <h5 className="black-text d-flex">
                                Wallet Address :{" "}
                                <div
                                  className="account ml-3"
                                >
                                  <h6 className="m-0">
                                    {auth.accounts.substr(0, 5)}...
                                    {auth.accounts.substr(auth.accounts.length - 4)}
                                  </h6>
                                </div>
                              </h5>
                              <h5 className="black-text">
                                Balance :{" "}
                                {Number(auth.ethBalance).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 3,
                                  }
                                )}
                                BNB
                              </h5>
                            </>
                          )}
                          {/* <h5 className="black-text">
                            Admin fee :{" "}
                            {configuration.get(
                              "configData.token_deploy_amount"
                            )}{" "}
                            %
                          </h5> */}
                          <h5 className="whitecolor">
                            1 BNB : {bnbAmount} USD
                          </h5>
                        </div>
                        <div>
                          <h3 className="text-center">Token Details </h3>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <h4>
                              {" "}
                              <span className="whitecolor text-bold">
                                Token Name
                              </span>{" "}
                              : {props.tokenData.data.name}
                            </h4>

                            <h5 className="black-text">
                              Token Symbol : {props.tokenData.data.token_symbol}
                            </h5>
                            <h5 className="whitecolor">
                              Token Decimal :{" "}
                              {props.tokenData.data.decimal_points}
                            </h5>
                            <h5 className="whitecolor">
                              Token Supply :{" "}
                              {props.tokenData.data.max_token_supply}
                            </h5>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <h5>
                              {" "}
                              <span className="whitecolor text-bold">
                                Initial Token Supply
                              </span>{" "}
                              : {props.tokenData.data.initial_token_supply}
                            </h5>
                            <h5 className="whitecolor">
                              Is Burnable :{" "}
                              {props.tokenData.data.burnable ? "Yes" : "No"}
                            </h5>
                            <h5 className="whitecolor">
                              Is Mintable :{" "}
                              {props.tokenData.data.mintable ? "Yes" : "No"}
                            </h5>
                            <h5 className="whitecolor">
                              Remove Copyrights :{" "}
                              {props.tokenData.data.remove_copyrights ? "Yes" : "No"}
                            </h5>
                          </div>
                        </div>
                        
                      </div>

                      <div className="my-3 my-lg-4">
                        {active === false && chainId == undefined ? (
                          <div className="buttons-wrapper">
                            <button
                              type="submit"
                              className="btn btn--medium  btn--transparent btn--primary ml-3 text-capitalize"
                              onClick={() => handleConnectWalletOpen()}
                            >
                              Connect Wallet
                            </button>
                          </div>
                        ) : paymentCompleted === false ? (
                          <div className="buttons-wrapper">
                            <button
                              type="submit"
                              className="btn btn--medium  btn--transparent btn--primary ml-3 text-capitalize"
                              onClick={handleSubmit}
                              disabled={
                                payMeButtonContent !== ""
                                  ? true
                                  : deployContractButtonContent !== ""
                                  ? true
                                  : false
                              }
                            >
                              {payMeButtonContent !== ""
                                ? payMeButtonContent
                                : deployContractButtonContent !== ""
                                ? deployContractButtonContent
                                : "Click here to pay"}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div>
                              <h3>Token Created Successfully</h3>
                              <h5 className="black-text">
                                Contract Address :{" "}
                              </h5>
                              <h5 className="account word-break">
                                {tokenContractData.contract_address}
                              </h5>
                              <h5 className="black-text">
                                Transaction Hash :{" "}
                              </h5>
                              <h5 className="account word-break">
                                {tokenContractData.transaction_hash}
                              </h5>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {connectWalletModalStatus && (
        <ConnectWalletModal
          status={connectWalletModalStatus}
          handleConnectWalletClose={handleConnectWalletClose}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  saveTokenPayAuto: state.token.saveTokenPayAuto,
  tokenData: state.token.tokenData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateTokenPayment);
