import React from "react";
import { Notify } from "react-redux-notify";

const LogoutLayout = (props) => {
  return (
    <>
      <Notify position="TopRight" />
      {props.children}
    </>
  );
};

export default LogoutLayout;
