import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { storeTokenData } from "../store/actions/TokenAction";
import { useHistory } from "react-router";

const CreateToken = (props) => {

  const history = useHistory();

  const [createTokenInput, setCreateTokenInput] = useState({
    name: "",
    token_symbol: "",
    decimal_points: "",
    max_token_supply: "",
    access_type: "None",
    transfer_type: "Unstoppable",
    remove_copyrights: false,
    burnable: false,
    mintable: false,
    network_type: "Binance",
    token_type: "equity",
  });

  const createTokenschema = Yup.object().shape({
    name: Yup.string().required("Token name is required"),
    token_symbol: Yup.string().required("Token symbol is required"),
    decimal_points: Yup.number().required("Token decimal points is required"),
    max_token_supply: Yup.number().required("Token total is required"),
    access_type: Yup.string().required("Access type is required"),
    transfer_type: Yup.string().required("transfer type is required"),
    remove_copyrights: Yup.boolean(),
    burnable: Yup.boolean(),
    mintable: Yup.boolean(),
    token_type: Yup.string().required("Token Type is required"),
  });

  const handleSubmit = async (values) => {
    setCreateTokenInput(values);
    props.dispatch(
      storeTokenData({
        ...values,
        description: "Some description static",
        initial_token_supply: "10000",
      })
    );
    history.push("/token-payment")
  };

  useEffect(() => {
    if(props.tokenData.data != null){
      setCreateTokenInput(props.tokenData.data)
    }
  },[])

  return (
    <>
      <div className="contact-us-sec create-token other_page_layouts">
        <div className="container-fluid">
          <Formik
            initialValues={createTokenInput}
            className="contact-us-form-sec "
            validationSchema={createTokenschema}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
          >
            {({ errors, touched, setFieldValue }) => (
              <Form noValidate>
                <div className="container no-padding">
                  <div className="custom-box p-3">
                    <div className="col-12">
                      <h1 className="text-bold m-0 text-center  mb-3">
                        Create Token
                      </h1>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="custom-box p-0">
                          <div className="createToken-header">
                            <div className="col-lg-12">
                              <h3 className="text-bold">Token Details</h3>
                            </div>
                          </div>
                          <div className="contact-us-form-sec col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
                            <div className="row ">
                              <div class="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h5>
                                  Token name <span class="text-danger">*</span>
                                </h5>

                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Token Name"
                                  className={` form-control ${
                                    touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback mt-3"
                                />
                                <p className="">
                                  Choose a name for your token.
                                </p>
                              </div>
                              <div class="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h5>
                                  Your Coin/ Token abbreviation{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="token_symbol"
                                    placeholder="Token Symbol"
                                    className={`form-control ${
                                      touched.token_symbol &&
                                      errors.token_symbol
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="token_symbol"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="">
                                    ( Ex: BTC, EOS, ETH, XLM etc. )
                                  </p>
                                </div>
                              </div>
                              <div class="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h5>
                                  Token Decimals{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="text"
                                    name="decimal_points"
                                    placeholder="Token Decimals"
                                    className={`form-control ${
                                      touched.decimal_points &&
                                      errors.decimal_points
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="decimal_points"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="">
                                    Decimals * (Maximum number of decimals)?
                                  </p>
                                </div>
                              </div>

                              <div class="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h5>
                                  Total Supply{" "}
                                  <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <Field
                                    type="number"
                                    name="max_token_supply"
                                    placeholder="Token Supply"
                                    className={`form-control ${
                                      touched.max_token_supply &&
                                      errors.max_token_supply
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="max_token_supply"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="">
                                    (Max number of coins to be created)
                                  </p>
                                </div>
                              </div>

                              <div class="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h5 className="black-text">
                                  Token Type <span class="text-danger">*</span>
                                </h5>
                                <div class="controls">
                                  <select
                                    name="token_type"
                                    id="token_type"
                                    className={`form-control ${
                                      touched.token_type && errors.token_type
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "token_type",
                                        event.target.value
                                      );
                                    }}
                                  >
                                    <option value="equity">Equity Token</option>
                                    <option value="asset">Asset Token</option>
                                    <option value="stable">Stable Coin</option>
                                    <option value="utility">
                                      Utility Token
                                    </option>
                                    <option value="others">others</option>
                                  </select>
                                  <ErrorMessage
                                    component="div"
                                    name="token_type"
                                    className="invalid-feedback mt-3"
                                  />
                                  <p className="">
                                    Choose the type of token you are trying to
                                    create (Utility, Asset, Equity)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="row ">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="custom-box p-0 ">
                              <div className="createToken-header">
                                <div className="col-lg-12">
                                  <h3 className="">Token Features</h3>
                                </div>
                              </div>
                              <div className="contact-us-form-sec">
                                <div class="form-group col-lg-12 ">
                                  <h5>
                                    Access Type{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <select
                                      name="access_type"
                                      id="access_type"
                                      className={`form-control  ${
                                        touched.access_type &&
                                        errors.access_type
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "access_type",
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option value="none">None</option>
                                      <option value="ownable">Ownable</option>
                                      <option value="role_based">
                                        Role Based
                                      </option>
                                    </select>
                                    <ErrorMessage
                                      component="div"
                                      name="access_type"
                                      className="invalid-feedback mt-3"
                                    />
                                    <p className="mb-0 mt-2 text-muted">
                                      None, Ownable, Role Based.
                                    </p>
                                  </div>
                                </div>
                                <div class="form-group col-lg-12">
                                  <h5>
                                    Transfer Type{" "}
                                    <span class="text-danger">*</span>
                                  </h5>
                                  <div class="controls">
                                    <select
                                      name="transfer_type"
                                      id="transfer_type"
                                      className={`form-control ${
                                        touched.transfer_type &&
                                        errors.transfer_type
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "transfer_type",
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <option value="Unstoppable">
                                        Unstoppable
                                      </option>
                                      <option value="Pausable">Pausable</option>
                                    </select>
                                    <ErrorMessage
                                      component="div"
                                      name="transfer_type"
                                      className="invalid-feedback mt-3"
                                    />
                                    <p className="mb-0 mt-2 text-muted">
                                      Unstoppable, Pausable
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="custom-box p-0 ">
                              <div className="createToken-header">
                                <h3 className="">Extra features</h3>
                              </div>
                              <div className="mt-3">
                                <div className="form-group">
                                  <div className="controls">
                                    <div className="custom-checkbox mb-3">
                                      <Field
                                        className="switch"
                                        type="checkbox"
                                        name="remove_copyrights"
                                      />
                                      <h5 className="">Remove Copyright</h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="controls">
                                    <div className="custom-checkbox mb-3">
                                      <Field
                                        className="switch"
                                        type="checkbox"
                                        name="burnable"
                                      />
                                      <h5 className="">Burnable</h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="controls">
                                    <div className="custom-checkbox mb-3">
                                      <Field
                                        className="switch"
                                        type="checkbox"
                                        name="mintable"
                                      />
                                      <h5 className="">Mintable</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="row no-gutters">
                          <div className="d-flex justify-content-center my-3 w-100">
                            <button
                              type="submit"
                              className="btn btn--medium  btn--transparent btn--primary ml-3 text-capitalize"
                            >
                              Create Token
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveToken: state.token.saveToken,
  tokenData: state.token.tokenData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateToken);
