import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import {
  getKycDocumentStart,
  getKYCDocumentsListStart,
  addKycDocumentStart,
  clearDocumentUploadData,
  deleteKYCDocumentStart,
  clearDeleteKycDocumentData,
} from "../../store/actions/KycDocumentAction";
import {
  fetchUserDetailsStart,
  updateUserDetailsStart,
} from "../../store/actions/UserAction";
import { authContext } from "../auth/AuthProvider";
import DocumentModal from "./DocumentModal";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const KycIndex = (props) => {
  const { auth } = useContext(authContext);

  const [requiredKycDocuments, setRequiredDocuments] = useState([]);

  const [expiryDate, setExpiryDate] = useState(new Date());

  const [kycModal, setKycModal] = useState({
    status: false,
    data: null,
    isApproved: null,
    picture_file: "",
  });

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
  });

  const kycSchema = Yup.object().shape({
    name: Yup.string().required("Name is required *"),
    email: Yup.string()
      .email("Must be a valid email address *")
      .required("Email Address is required *"),
  });

  useEffect(() => {
    if (auth.userId != "") {
      props.dispatch(fetchUserDetailsStart());
      props.dispatch(getKYCDocumentsListStart());
    }
  }, [auth.userId]);

  useEffect(() => {
    if (
      !props.kycList.loading &&
      Object.keys(props.kycList.data.documents).length > 0
    ) {
      setRequiredDocuments(props.kycList.data.documents);
      setKycModal({
        ...kycModal,
        isApproved: props.kycList.data.is_document_verified != 2 ? false : true,
      });
    }
  }, [props.kycList.loading]);

  useEffect(() => {
    if (
      !props.profileDetails.loading &&
      Object.keys(props.profileDetails.data).length > 0
    ) {
      setUserDetails({
        ...userDetails,
        name: props.profileDetails.data.name,
        email: props.profileDetails.data.email,
      });
    }
  }, [props.profileDetails.loading]);

  const handleDocumentImageChange = useCallback(
    (event) => {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        // setRequiredDocuments([...requiredKycDocuments.map((documents) => {
        //   if(documents.document_id == kycModal.data.document_id){
        //     return {
        //       ...documents,
        //       picture : reader.result,
        //       image_type : file.type
        //     }
        //   }
        //   return documents
        // })])

        setKycModal({
          ...kycModal,
          data: {
            ...kycModal.data,
            picture: reader.result,
            image_type: file.type.replace(/(.*)\//g, ""),
          },
          picture_file: file,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    [requiredKycDocuments, kycModal]
  );

  const handleKycModalOpen = useCallback(
    (status, Dataindex) => {
      const selectedDocument = requiredKycDocuments.find(
        (documents) => documents.document_id == Dataindex
      );
      setKycModal({
        ...kycModal,
        status: status,
        data: selectedDocument,
      });
      if (Object.keys(selectedDocument.user_document).length > 0) {
        setExpiryDate(new Date(selectedDocument.user_document.expiry_date));
      }
    },
    [requiredKycDocuments]
  );

  const handleKycModalClose = useCallback(() => {
    setKycModal({
      ...kycModal,
      status: false,
      data: null,
      picture_file: "",
    });
    setExpiryDate(new Date());
  }, []);

  useEffect(() => {
    if (kycModal.status) {
      document.body.classList.add("noBodyOverflow");
    } else {
      document.body.classList.remove("noBodyOverflow");
    }
  }, [kycModal.status]);

  const handleDocumentUpload = useCallback(() => {
    props.dispatch(
      addKycDocumentStart({
        document_files: JSON.stringify([
          {
            document_file:
              kycModal.picture_file != "" ? kycModal.data.picture : "",
            document_id: kycModal.data.document_id,
            expiry_date: kycModal.data.is_required == 1 ? expiryDate : null,
            user_document_id:
              Object.keys(kycModal.data.user_document).length > 0
                ? kycModal.data.user_document.user_document_id
                : 0,
          },
        ]),
      })
    );
  }, [kycModal, expiryDate]);

  const handleDeleteDocument = (user_document_id) => {
    props.dispatch(
      deleteKYCDocumentStart({
        user_document_id: user_document_id,
      })
    );
  };

  useEffect(() => {
    if (
      !props.addKycDocInput.loading &&
      props.addKycDocInput.data &&
      Object.keys(props.addKycDocInput.data).length > 0
    ) {
      setKycModal({
        status: false,
        data: null,
        picture_file: "",
      });
      props.dispatch(clearDocumentUploadData());
    }
  }, [props.addKycDocInput.loading]);

  useEffect(() => {
    if (
      !props.deleteDocument.loading &&
      props.deleteDocument.data &&
      Object.keys(props.deleteDocument.data).length > 0
    ) {
      props.dispatch(clearDeleteKycDocumentData());
    }
  }, [props.deleteDocument.loading]);

  // useEffect(() => {
  //   if (
  //     !props.profileInputData.loading &&
  //     props.profileInputData.status
  //   ) {
  //     props.dispatch(fetchUserDetailsStart());
  //   }
  // }, [props.profileInputData.loading]);

  const handleUserDetailsChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleUserProfileSubmit = (values) => {
    props.dispatch(updateUserDetailsStart(values));
  };

  return (
    <>
      <div id="payment-details">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 mb-xs-0 px-xs-0">
          <div className="tableHeadingcustom">
            <h4 className="text-al secondary-text">KYC verification</h4>
          </div>
          <div className="">
            {kycModal.isApproved && (
              <h3 className="text-center">Account has been approved.</h3>
            )}
            {props.kycList.data.is_document_verified == 3 && (
              <h3 className="text-center">Account has been declined, please re-upload.</h3>
            )}
            {requiredKycDocuments.length > 0 && (
              <>
                <div className="row no-margin">
                  {requiredKycDocuments.map((documents, index) => (
                    <>
                      <div
                        className="col-md-6 col-lg-4 col-sm-12 col-lg-12 no-padding d-flex"
                        key={index}
                      >
                        {Object.keys(documents.user_document).length > 0 &&
                        documents.user_document.document_file != "" ? (
                          <>
                            <div className="document-images-preview-wrapper">
                              <div
                                className={`document-images-preview ${
                                  kycModal.isApproved ? "approved" : ""
                                }`}
                              >
                                <img
                                  src={documents.user_document.document_file}
                                ></img>
                                {!kycModal.isApproved &&
                                  Object.keys(documents.user_document).length >
                                    0 &&
                                  documents.user_document.document_file && (
                                    <>
                                      <div
                                        className="kyc-img-overlay"
                                        onClick={
                                          !kycModal.isApproved
                                            ? () =>
                                                handleKycModalOpen(
                                                  true,
                                                  documents.document_id
                                                )
                                            : null
                                        }
                                      >
                                        <i class="far fa-image"></i>
                                      </div>
                                    </>
                                  )}

                                {!kycModal.isApproved &&
                                  Object.keys(documents.user_document).length >
                                    0 &&
                                  documents.user_document.document_file && (
                                    <>
                                      <div className="file-remover">
                                        <button
                                          className="btn not-btn text-center "
                                          type="button"
                                          disabled={
                                            props.deleteDocument.buttonDisable
                                          }
                                          onClick={() =>
                                            handleDeleteDocument(
                                              documents.user_document
                                                .user_document_id
                                            )
                                          }
                                        >
                                          <i class="fas fa-trash"></i>
                                        </button>
                                      </div>
                                    </>
                                  )}
                              </div>
                              <div className="document-details">
                                <h5>{documents.name}</h5>
                                {documents.is_expiry_date == 1 && (
                                  <h5>{documents.user_document.expiry_date}</h5>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="document-images-preview-wrapper">
                              <div
                                className={`document-images-preview ${
                                  kycModal.isApproved ? "approved" : ""
                                }`}
                              >
                                <img src={documents.picture}></img>
                                <div
                                  className="kyc-img-overlay"
                                  onClick={
                                    !kycModal.isApproved
                                      ? () =>
                                          handleKycModalOpen(
                                            true,
                                            documents.document_id
                                          )
                                      : null
                                  }
                                >
                                  <i class="far fa-image"></i>
                                  <div>
                                    <p>
                                      Click here to upload your {documents.name}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="document-details">
                                <h5>{documents.name}</h5>
                              </div> */}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
            <Formik
              initialValues={{
                name: userDetails.name,
                email: userDetails.email,
              }}
              validationSchema={kycSchema}
              onSubmit={(values) => handleUserProfileSubmit(values)}
              enableReinitialize
            >
              {({ touched, errors, isSubmitting }) => (
                <Form
                  className="form--search form--search-transparent w-100"
                  noValidate
                >
                  <div className="row no-margin w-100">
                    <div className="w-100 col-lg-12 col-md-12 col-xs-12 no-padding">
                      <h4 className="text-center">Profile Details</h4>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="ProjectName"
                        className="custom-label ml-0 text-capitalize primary-text"
                      >
                        Name{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="ProjectName"
                        className={`no-padding form-control ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                        name="name"
                        placeholder="Name"
                        type="text"
                        onChange={(event) => handleUserDetailsChange(event)}
                        value={userDetails.name}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb30 addproject-between">
                      <label
                        htmlFor="ProjectName"
                        className="custom-label ml-0 text-capitalize primary-text"
                      >
                        Email{" "}
                        <abbr className="required" title="required">
                          *
                        </abbr>
                      </label>
                      <Field
                        id="ProjectName"
                        className={`no-padding form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                        name="email"
                        placeholder="Email Address"
                        type="email"
                        onChange={(event) => handleUserDetailsChange(event)}
                        value={userDetails.email}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback mt-3"
                      />
                    </div>
                    <div className="button-wrapper w-100">
                      <button
                        type="submit"
                        className="btn btn--medium btn--transparent btn--primary"
                        disabled={props.profileInputData.buttonDisable}
                      >
                        {props.profileInputData.loadingButtonContent !== null
                          ? props.profileInputData.loadingButtonContent
                          : "Update profile"}
                      </button>
                    </div>
                    {!kycModal.isApproved && (
                      <>
                        <h5 className="c-primary mt-3">
                          Note: Click on the Document image to be able to upload
                          the document. Low-quality documents will be rejected.
                          Name, and Email Address are mandatory.
                        </h5>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {kycModal.status && (
        <DocumentModal
          status={kycModal.status}
          data={kycModal.data}
          handleModalClose={handleKycModalClose}
          handleDocumentImageChange={handleDocumentImageChange}
          expiryDate={expiryDate}
          setExpiryDate={setExpiryDate}
          handleDocumentUpload={handleDocumentUpload}
          picture_file={kycModal.picture_file}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profileDetails: state.users.profile,
  kycList: state.kycDocument.kycList,
  addKycDocInput: state.kycDocument.addKycDocInput,
  deleteDocument: state.kycDocument.deleteDocument,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(KycIndex);
