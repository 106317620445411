import {
 FETCH_SINGLE_TOKEN_START,
 FETCH_SINGLE_TOKEN_SUCCESS,
 FETCH_SINGLE_TOKEN_FAILURE,
 SAVE_TOKEN_START,
 SAVE_TOKEN_SUCCESS,
 SAVE_TOKEN_FAILURE,
 SAVE_TOKEN_PAYMENT_AUTO_START,
 SAVE_TOKEN_PAYMENT_AUTO_SUCCESS,
 SAVE_TOKEN_PAYMENT_AUTO_FAILURE,
 SAVE_TOKEN_PAYMENT_MANUAL_START,
 SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS,
 SAVE_TOKEN_PAYMENT_MANUAL_FAILURE,
 SAVE_TOKEN_CONTRACT_ADDR_START,
 SAVE_TOKEN_CONTRACT_ADDR_SUCCESS,
 SAVE_TOKEN_CONTRACT_ADDR_FAILURE,
 FETCH_TOKEN_LIST_START,
 FETCH_TOKEN_LIST_SUCCESS,
 FETCH_TOKEN_LIST_FAILURE,
 FETCH_PAYMENT_DETAILS_START,
 FETCH_PAYMENT_DETAILS_SUCCESS,
 FETCH_PAYMENT_DETAILS_FAILURE,
 TEMP_SAVE_TOKEN_START,
 TEMP_SAVE_TOKEN_SUCCESS,
 TEMP_SAVE_TOKEN_FAILURE,
 STORE_TOKEN_DATA
} from "./ActionConstant";



// save token actions.

export function saveTokenStart(data) {
 return {
  type: SAVE_TOKEN_START,
  data,
 };
}

export function saveTokenSuccess(data) {
 return {
  type: SAVE_TOKEN_SUCCESS,
  data,
 };
}

export function saveTokenFailure(error) {
 return {
  type: SAVE_TOKEN_FAILURE,
  error,
 };
}

// Fetch single token. 

export function fetchSingleTokenStart(data) {
 return {
  type: FETCH_SINGLE_TOKEN_START,
  data,
 };
}

export function fetchSingleTokenSuccess(data) {
 return {
  type: FETCH_SINGLE_TOKEN_SUCCESS,
  data,
 };
}

export function fetchSingleTokenFailure(error) {
 return {
  type: FETCH_SINGLE_TOKEN_FAILURE,
  error,
 };
}

// Save token

export function saveTokenPaymentAutoStart(data) {
 return {
  type: SAVE_TOKEN_PAYMENT_AUTO_START,
  data,
 };
}

export function saveTokenPaymentAutoSuccess(data) {
 return {
  type: SAVE_TOKEN_PAYMENT_AUTO_SUCCESS,
  data,
 };
}

export function saveTokenPaymentAutoFailure(error) {
 return {
  type: SAVE_TOKEN_PAYMENT_AUTO_FAILURE,
  error,
 };
}


//SAVE_TOKEN_PAYMENT_MANUAL. 

export function saveTokenPaymentManualStart(data) {
 return {
  type: SAVE_TOKEN_PAYMENT_MANUAL_START,
  data,
 };
}

export function saveTokenPaymentManualSuccess(data) {
 return {
  type: SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS,
  data,
 };
}

export function saveTokenPaymentManualFailure(error) {
 return {
  type: SAVE_TOKEN_PAYMENT_MANUAL_FAILURE,
  error,
 };
}


//SAVE_TOKEN_CONTRACT_ADDR

export function saveTokenContractAddrStart(data) {
 return {
  type: SAVE_TOKEN_CONTRACT_ADDR_START,
  data,
 };
}

export function saveTokenContractAddrSuccess(data) {
 return {
  type: SAVE_TOKEN_CONTRACT_ADDR_SUCCESS,
  data,
 };
}

export function saveTokenContractAddrFailure(error) {
 return {
  type: SAVE_TOKEN_CONTRACT_ADDR_FAILURE,
  error,
 };
}

//FETCH_TOKEN_LIST

export function fetchTokenListStart(data) {
 return {
  type: FETCH_TOKEN_LIST_START,
  data,
 };
}

export function fetchTokenListSuccess(data) {
 return {
  type: FETCH_TOKEN_LIST_SUCCESS,
  data,
 };
}

export function fetchTokenListFailure(error) {
 return {
  type: FETCH_TOKEN_LIST_FAILURE,
  error,
 };
}

//FETCH_PAYMENT_DETAILS

export function fetchPaymentDetailsStart(data) {
 return {
  type: FETCH_PAYMENT_DETAILS_START,
  data,
 };
}

export function fetchPaymentDetailsSuccess(data) {
 return {
  type: FETCH_PAYMENT_DETAILS_SUCCESS,
  data,
 };
}

export function fetchPaymentDetailsFailure(error) {
 return {
  type: FETCH_PAYMENT_DETAILS_FAILURE,
  error,
 };
}

//TEMP_SAVE_TOKEN

export function tempSaveTokenStart(data) {
 return {
  type: TEMP_SAVE_TOKEN_START,
  data,
 };
}

export function tempSaveTokenSuccess(data) {
 return {
  type: TEMP_SAVE_TOKEN_SUCCESS,
  data,
 };
}

export function tempSaveTokenFailure(error) {
 return {
  type: TEMP_SAVE_TOKEN_FAILURE,
  error,
 };
}

export function storeTokenData(data) {
  return {
   type: STORE_TOKEN_DATA,
   data,
  };
 }




